import WPDarkMode from "./DarkMode";
import Store from "./Store";
import Images from "./Images";
import Videos from "./Videos";
import Accessibility from "./Accessibility";
import Color from "./Color";
import Base from "./Base";

class DarkModeApp extends Base {

	/**
	 * Events
	 */
	events() {

		// Sync storages.
		this.syncStorage();
		
		// Register event listeners.
		this.RegisterEvents();

		// Emit init
		this.emit('init');

		return this;
	}

	/**
	 * Sync storages
	 */
	async syncStorage() {
		// Update user timezone.
		let timeZoneOffset = (new Date().getTimezoneOffset()/60);

		// Add + sign if offset is negative. Add - sign if offset is positive.
		if (timeZoneOffset > 0) {
			timeZoneOffset = '-' + timeZoneOffset;
		} else {
			timeZoneOffset = '+' + Math.abs(Number(timeZoneOffset));
		}

		Store.set( 'timezone', timeZoneOffset );

		// Update user device preference.
		Store.set( 'device', WPDarkMode.isDeviceDark ? 'dark' : 'light' );

		if ( wp_dark_mode_json.options.frontend_mode === 'sunset' ) {
			
			// Get device information.
			this.updateDeviceInformation()
		}
	}

	/**
	 * Update device information
	 */
	async updateDeviceInformation() {

		// Check if location was updated in last 24 hours.
		const locationUpdated = Store.get( 'location-updated' );

		// Bail if location was updated in last 24 hours.
		if (locationUpdated) {
			if (((new Date()) - (new Date().setTime( locationUpdated ))) < (24 * 60 * 60 * 1000)) {
				return;
			}
		}

		// Bail, if navigator is not available or geolocation is not supported.
		if ( ! navigator || ! navigator.geolocation ) {
			return;
		}

		navigator.geolocation.getCurrentPosition( (position) => {
			// Save location for dark mode.
			Store.set( 'location', position.coords.latitude + ',' + position.coords.longitude );

			// Save location update time.
			Store.set( 'location-updated', new Date().getTime() );
		});

	}
	/**
	 * Follow device preference for dark mode
	 */
	followDevicePreference() {
		// Bail, if user choice is set.
		if (Store.get( 'choice' ) !== null) {
			return;
		}

		if (WPDarkMode.isDeviceDark) {
			WPDarkMode.activate();
		} else {
			WPDarkMode.deactivate();
		}
	}

	/**
	 * Register event listeners
	 */
	RegisterEvents() {
		// Track device settings.
		this.TrackDeviceSettings();

		document.addEventListener('DOMContentLoaded', this.HandleExcludes );

		// Apply colors when dark mode is changed.
		Color.init();

		Accessibility.init();

		// Media. 
		Images.init();

		Videos.init();
	}

	/**
	 * Listen for device dark mode change
	 */
	TrackDeviceSettings() {

		// Bail, if device mode change is not supported.
		if ( ! window || ! window.matchMedia ) {
			return;
		}

		const mediaQuery = matchMedia('(prefers-color-scheme: dark)');

		if( ! mediaQuery ) return false;

		mediaQuery.addEventListener('change', function() {

			const isDark = mediaQuery.matches
			
			// Update user device preference.
			Store.set( 'device', isDark ? 'dark' : 'light' );

			// React to device preference change.
			
			// Bail, if user choice is set.
			if (Store.get( 'choice' ) !== null) {
				return;
			}

			// Bail, if dark mode is not following device mode.
			if ( wp_dark_mode_json.options.frontend_mode !== 'device' ) return;

			// React to device preference change.
			if (isDark) {
				WPDarkMode.activate();
			} else {
				WPDarkMode.deactivate();
			}

		});
	}



}

export default new DarkModeApp();